body {
    font-family: 'Open Sans';
    background-color: hsl(237 50% 98% / 1);
}

.textInput::placeholder {
    color: #97bace;
}

.vis-network:focus {
    outline: 0;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.rotating {
    animation: rotating 2s linear infinite;
}

a {
    text-decoration: none;
    color: #97bace;
}

tr.borderBottom td {
    border-bottom: 1px solid #ecf4f9;
}

tr.noBorder td {
    border: 0px;
}

.vis-tooltip {
    border: 1px solid #97bace !important;
    background-color: #ecf4f9 !important;
    color: hsl(205,49%,66%) !important;
}
